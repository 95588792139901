<template>
  <!-- hidden PageHeaderWrapper title demo -->
  <page-header-wrapper :title="false">
    <a-spin :spinning="loading">
      <a-card :body-style="{padding: '24px 32px'}" :bordered="false">
        <a-form :form="form">
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="站点名称"
            :validateStatus="checkParams.titleStatus"
            :help="checkParams.titleStatusMsg"
          >
            <a-input
              style="width: 300px;"
              placeholder="请输入站点名称"
              v-decorator="['title', {initialValue: queryParam.applyName, rules: [{required: true, validator: titleCheck, validateTrigger: 'change'}]}]"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="电话"
          >
            <a-input
              style="width: 300px;"
              v-model="queryParam.telephone"
              placeholder="请输入电话号码"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="邮箱"
          >
            <a-input
              style="width: 300px;"
              v-model="queryParam.email"
              placeholder="请输入邮箱"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="简介"
          >
            <a-textarea
              style="width: 70%;"
              :rows="5"
              v-model="queryParam.content"
              placeholder="简介"
            />
          </a-form-item>
          <a-form-item
            :wrapperCol="{ span: 24 }"
            style="padding-left: 300px;"
          >
            <a-button
              type="primary"
              @click="saveApi"
              :disabled="saveDisabled || queryParam.checkStatus === 1"
              :loading="saveLoading"
            >
              提交
            </a-button>
          </a-form-item>
        </a-form>
      </a-card>
    </a-spin>
  </page-header-wrapper>
</template>

<script>
import { applyInfo, setApply } from '@/api/apply'
export default {
  name: 'ApplySite',
  data () {
    return {
      previewInfo: {
        previewVisible: false,
        previewTitle: '',
        previewImage: ''
      },
      isImageUpload: false,
      uploading: false,
      fileList: [],
      uploadImageInfo: {
        fileName: '',
        url: '',
        width: 0,
        height: 0,
        size: 0,
        bucket: 'dadaojianqi-syue',
        type: 0,
        transcoding: 0
      },
      tokenInfo: {
        'region': '',
        'AccessKeyId': '',
        'AccessKeySecret': '',
        'SecurityToken': '',
        'bucket': '',
        'domain': ''
      },
      form: this.$form.createForm(this, { name: 'addNoticeFrom' }),
      loading: false,
      saveDisabled: false,
      saveLoading: false,
      labelCol: {
        lg: { span: 2 },
        sm: { span: 7 }
      },
      wrapperCol: {
        lg: { span: 17 },
        sm: { span: 17 }
      },
      checkParams: {
        titleStatus: 'success',
        titleStatusMsg: null,
        contentStatus: 'success',
        contentStatusMsg: null,
        noticeTypeStatus: 'success',
        noticeTypeStatusMsg: null,
        sendTypeStatus: 'success',
        sendTypeStatusMsg: null
      },
      queryParam: {
        applyName: '',
        content: '',
        email: '',
        telephone: ''
      }
    }
  },
  created () {
    this.applyInfoApi()
  },
  methods: {
    titleCheck (rule, value, callback) {
      const _this = this
      _this.queryParam.applyName = value.trim()
      if (_this.queryParam.applyName !== '') {
        _this.checkParams.titleStatus = 'success'
        _this.checkParams.titleStatusMsg = null
      } else {
        _this.checkParams.titleStatus = 'error'
        _this.checkParams.titleStatusMsg = '请输入站点名称'
        callback(new Error('请输入站点名称'))
      }
    },
    saveApi () {
      console.log('saveApi')
      const _this = this
      if (_this.queryParam.applyName !== '') {
        _this.checkParams.titleStatus = 'success'
        _this.checkParams.titleStatusMsg = null
      } else {
        _this.checkParams.titleStatus = 'error'
        _this.checkParams.titleStatusMsg = '请输入站点名称'
        return false;
      }
      setApply(_this.queryParam).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.$message.success('操作成功')
          setTimeout(() => {
            location.reload()
          }, 1000)
          // _this.$router.replace('/system/apply-site')
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    applyInfoApi () {
      const _this = this
      applyInfo().then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.queryParam = res.result
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>
