import request from '@/utils/request'
const baseURL = '/baseapi'
const applyApi = {
  ApplyInfo: '/admin/apply/applyInfo',
  SetApply: '/admin/apply/setApply'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */

export function setApply (parameter) {
  return request({
    baseURL: baseURL,
    url: applyApi.SetApply,
    method: 'post',
    data: parameter
  })
}

export function applyInfo () {
  return request({
    baseURL: baseURL,
    url: applyApi.ApplyInfo,
    method: 'get'
  })
}
